const getNetworkData = (network) => {
    // console.log("environment", process.env.NODE_ENV);
    let additionalProperties = {};
    let networkName = process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging" ? "Testnet" : "Mainnet"
    const ETH_RPC_URL = process.env.REACT_APP_ETH_RPC_URL;
    const BSC_RPC_URL  = process.env.REACT_APP_BSC_RPC_URL;
    const MATIC_RPC_URL = process.env.REACT_APP_MATIC_RPC_URL;

      if (network === "BSC") {
        networkName = "BSC " + networkName;
        additionalProperties = getNetworkProperties(networkName, "BNB", [
          BSC_RPC_URL,
        ]);
      } else if (network === "ETH") {
        networkName = "Goerli " + networkName;
        additionalProperties = getNetworkProperties(networkName, "ETH", [
          ETH_RPC_URL,
        ]);
      } else if (network === "MATIC") {
        networkName = "Polygon-Mumbai " + networkName;
        additionalProperties = getNetworkProperties(networkName, "MATIC", [
          MATIC_RPC_URL,
        ]);
      }
    
    return additionalProperties;
  };
  
  const getNetworkProperties = (chainName, symbol, rpcUrls) => {
    return {
      chainName,
      nativeCurrency: { decimals: 18, symbol: symbol },
      rpcUrls,
    };
  };

  export const NetworkHelper = {
    getNetworkData
  }