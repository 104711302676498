import { NetworkHelper } from "../utils/networkHelper";
import Web3 from "web3";
import WalletManager from "./WalletManager.service";

class WalletStructure {
  web3;
  provider;

  initialize() {}

  async changeNetwork({ name, chainId, web3, infuraId }) {
    this.web3 = web3;
    try {
      await this.web3.currentProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.web3.utils.toHex(chainId) }],
      });
    } catch (error) {
      console.log(error);
      if(error.message && error.message.indexOf('Unrecognized chain ID')!=-1){
        await this.web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              ...NetworkHelper.getNetworkData(name),
              chainId: this.web3.utils.toHex(chainId),
            },
          ],
        });
      }
      else if (error.code == 4902) {
        await this.web3.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              ...NetworkHelper.getNetworkData(name),
              chainId: this.web3.utils.toHex(chainId),
            },
          ],
        });
      }
    }
  }

  enableWallet() {}

  async initContract(abi, address, web3) {
    return await new web3.eth.Contract(abi, address);
  }

  getNetworkId(web3) {
    return web3.eth.net.getId();
  }

  async getAccounts(web3) {
    return await web3.eth.getAccounts();
  }

  async getBalance(account, web3) {
    return await web3.eth.getBalance(account);
  }
}

export default WalletStructure;
