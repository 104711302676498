/* eslint-disable no-useless-constructor */
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Enviroments } from "../constants/constants";
import { CollectionActions } from "../redux/actions/collection.action";
import WalletStructure from "./walletStructure.service";

class WalletConnect extends WalletStructure{

  initialize(chainId, infuraId){
    return this.intitalizeProvider(chainId, infuraId);
  }

  intitalizeProvider = (chainId, infuraId) => {
    return new WalletConnectProvider({
      infuraId: infuraId, // Required
      rpc: Enviroments.WC_RPC,
      qrcode: true,
      chainId: !chainId ? 5 : chainId,
      qrcodeModalOptions: Enviroments.QR_CODE_LINKS,
    });
  };

  async enableWallet(redux,chainId, infuraId){
    try {
      this.provider = this.initialize(chainId, infuraId)
      this.subscribeAccountChanges(redux);
      const address = await this.provider.enable();
      return { accounts:address, provider: this.provider };
    } catch (error) {
      throw error;
    }
  }

  // Subscribe to accounts change
  subscribeAccountChanges(redux){
    this.provider.on("accountsChanged", (accounts) => {
      if (redux && redux.dispatch) {
        const { dispatch } = redux;
        dispatch(
          CollectionActions.saveCollectionWalletAddress({
            walletAddress: accounts[0],
          })
        );
        dispatch(
          CollectionActions.saveWalletType({ walletType: "WALLETCONNECT" })
        );
      }
    });
  };

  // Subscribe to chainId change
  async subscribeChainIdChanges(){
    return new Promise((resolve, reject) => {
      this.provider.on("chainChanged", (chainId) => {
        console.log(chainId);
        resolve(chainId);
      });
    });
  };

}

export default new WalletConnect();
