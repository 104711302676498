import * as CryptoJS from "crypto-js";
import { Enviroments } from "../constants/constants";


function getKeyFromStorage(){
  let keyToDecode = JSON.parse(localStorage.getItem('persist:root'))
  keyToDecode = Enviroments.ENCRYPTION_KEY + JSON.parse(keyToDecode.persist).cryptoToken.replace('-','=');
  return keyToDecode;
}

function hexDecode(data) {
  let str2 = "";
  for (const st of data) {
    if (st == st.toUpperCase()) {
      str2 += st.toLowerCase();
    } else {
      str2 += st.toUpperCase();
    }
  }
  return Buffer.from(str2,"base64").toString("utf8");
}

function requestDecryption(data) {
  if (data) {
    let keyToDecode = getKeyFromStorage()
    const key = hexDecode(keyToDecode);
    return CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
  }
  return "";
}

function requestEncryption(data) {
  let keyToDecode = getKeyFromStorage()
  const key = hexDecode(keyToDecode);
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    key
  );
  return { data: ciphertext.toString() };
}

export const EncryptionHelper = {
  decrypt: requestDecryption,
  encrypt: requestEncryption,
};
